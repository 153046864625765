<template>
  <div>
    <div id="collectionSend">
      <div class="wrapper" :class="!isApp ? 'appTop' : ''">
        <div class="coll-box">
          <div class="choose-title">
            <div class="" @click="chooseAllMeth">{{ !chooseAll ? '全选' : '取消全选' }}</div>
          </div>
          <div v-for="(item, index) in collectionList" :key="index">
            <div class="coll-item" v-if="item.int">
              <div class="item-left" @click="chooseColl(item)">
                <div class="icon">
                  <van-icon :name="item.checked ? coll_choose : coll_no_choose" class="" />
                </div>
                <div class="flex">
                  <div class="img-box">
                    <img :src="item.items[0].image_icon" alt="" />
                    <div class="tranNum" v-if="item.trans_num">
                      {{ item.trans_num !== item.items_num ? item.trans_num + '个' : '' }}转赠中
                    </div>
                    <!-- <div class="num">x{{ item.cnt }}</div> -->
                  </div>
                  <div class="content">
                    <div class="display_name">{{ item.items[0].display_name }}</div>
                    <div class="items_name">
                      {{ item.items[0].items_name }}x
                      {{ item.int }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="addnum" v-if="item.checked">
                <van-stepper
                  v-model="item.addnum"
                  :max="item.int"
                  :min="1"
                  class="stepper"
                  @change="value => stepChange(value, item)"
                />
                <!-- <div class="intSend">{{ item.int }}个可转赠</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-bootm">
        <div class="flex bt-head">
          <div class="footerBtn">
            <div class="btn-none" @click="cancel">取消</div>
            <div class="btn" @click="isShowInfo">转赠 ({{ sendNum }})</div>
          </div>
        </div>
      </div>
      <div v-if="!collectionList.length" class="dataNullBox">
        <div>
          <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无可转赠藏品</div>
      </div>

      <!-- 转赠人信息检验弹窗 -->
      <van-popup
        v-model:show="infoShow"
        :closeable="true"
        @close="closePop"
        :close-on-click-overlay="false"
      >
        <div class="infoPopup">
          <div v-if="step == 1 || step == 2">
            <div class="title">转赠信息</div>
            <div>
              <div class="flexBox">
                <div class="label">转赠藏品</div>
                <div class="sendMsg">
                  <div v-for="(item, index) in checkedList" :key="index">
                    <span class="type" v-if="levelsEum[item.levels]">{{
                      levelsEum[item.levels]
                    }}</span>
                    <span>{{ item.display_name }}</span>
                    <span
                      >{{ item.open_status == 1 ? item.items_name : '盲盒' }} x {{ item.num }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="flexBox itemCenter">
                <div class="label">转赠价格</div>
                <div class="priceItem">
                  <van-field
                    class="priceInput"
                    :border="false"
                    type="number"
                    v-model="sendPrice"
                    maxlength="8"
                    @update:model-value="e => (sendPrice = inputPriceRule(e, 1))"
                    :disabled="step == 2"
                    placeholder="输入价格"
                  />元
                </div>
              </div>
              <div class="flexBox itemCenter" v-if="sendPrice > 0">
                <div class="label"></div>
                <!-- <div class="priceRate">手续费{{ (sendPrice * 0.056).toFixed(2) }}元</div> -->
                <div class="priceRate">手续费0元（限时免费）</div>
              </div>

              <div class="flexBox itemCenter">
                <div class="label">接收人</div>
                <div>
                  <van-field
                    class="mobileInput"
                    v-model="mobile"
                    maxlength="11"
                    :border="false"
                    :disabled="step == 2"
                    placeholder="请输入接受人手机号或ID"
                  />
                </div>
              </div>

              <div v-if="step == 2" class="flexBox itemCenter">
                <div class="label">接收人</div>
                <div>{{ sendUserInfo.id_name }}</div>
              </div>
            </div>
            <!-- <div class="btns">
              <div @click="submitPhone">提交</div>
            </div> -->
            <div v-if="step == 1" class="btns">
              <div class="cancelBtn" @click="closeInfoShow">取消</div>
              <div @click="priceTip">确定</div>
            </div>
            <div v-if="step == 2" class="btns">
              <div class="cancelBtn" @click="step = 1">上一步</div>
              <div @click="sureStep">确定</div>
            </div>

            <div class="sendTips" v-if="step == 1">
              <p>1.商品未经确认签收前，可取消转赠。一经签收，无法取消转赠；</p>
              <p>2.每次转赠将扣除：平台服务费5%（推广期间免平台服务费）；</p>
              <p>3.商品转赠成功后，转赠收入将默认进入余额账户；</p>
              <p>4.商品转赠的增值收入部分，请自行申报个人所得税。</p>
            </div>
          </div>

          <div v-if="step == 3">
            <div class="title">短信验证</div>
            <!-- <van-field
              class="input"
              :border="false"
              v-model="sendUserInfo.id_name"
              placeholder="请输入姓名"
            /> -->
            <div>
              <div class="flexBox itemCenter">
                <div class="label labelVerify">手机号</div>
                <div>{{ myPhone }}</div>
              </div>
              <div class="flexBox itemCenter">
                <div class="label labelVerify">验证码</div>
                <div class="verifyBox">
                  <van-field
                    center
                    clearable
                    class="verify"
                    maxlength="6"
                    v-model="verify_code"
                    placeholder="输入验证码"
                    @blur="verifyBlur"
                    :border="false"
                  />
                  <!--  -->
                  <div v-if="verifyHint" class="verify-warn">
                    <span>{{ verifyHint ? verifyHint : '请输入正确的验证码' }}</span>
                  </div>

                  <van-button
                    size="small"
                    class="sendverify"
                    type="default"
                    @click="clickSendSms"
                    hairline
                    :disabled="complainSMS.disabled"
                    >{{ complainSMS.text }}</van-button
                  >
                </div>
              </div>

              <div class="tips" :style="verifyHint ? 'margin-top:30px;' : ''">
                为保证您的数据安全，我们将向您注册的手机号发送验证码。请输入验证码，确认是您本人操作。
              </div>
            </div>
            <div class="btns">
              <div class="cancelBtn" @click="step = 2">上一步</div>
              <div @click="confirmSend">确认</div>
            </div>
          </div>
        </div>
      </van-popup>

      <popup
        v-model:show.sync="sendTip"
        title="风险提示"
        desc="外部收款交易存在受骗风险，是否0元转赠以上藏品"
        :btncon="{ cancel: '取消', success: '转赠' }"
        :closeable="false"
        @confirm="submitPhone"
        @cancel="sendTip = false"
      />

      <popup
        v-model:show.sync="pointTip"
        title="积分领取提示"
        :desc="'有' + pointSum + '修炼积分待领取，继续转赠将放弃未领取的积分，请前往领取'"
        :btncon="{ cancel: '放弃领取', success: '去领积分' }"
        @confirm="toLearning"
        @cancel="isShowInfo(false)"
      />
      <PageLoading :show="loading" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, inject } from 'vue';
import {
  getusercollectionsfold,
  contributeCertification,
  makeCollection,
  sandpayCloud,
  isonesendsms
} from '@/service/user';
import { nftUtils } from '@/utils';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { sendSms } from '@/service/user';
import popup from '@/components/popup/index.vue';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
export default {
  components: {
    popup,
    PageLoading
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toastFn = inject('$toast');
    const store = useStore();
    const state = reactive({
      tab_type: 0,
      collectionList: [],
      isApp: nftUtils.isInApp(),
      chooseAll: false,
      sendUserInfo: null, // 转赠验证人信息
      items_id_list: [],
      infoShow: false,
      step: 1,
      loading: true,
      checkedList: [],
      /***转赠弹窗字段***/
      mobile: null, // 转赠前实名验证手机号
      sendPrice: null,
      verify_code: null,
      /******/
      sendTip: false,
      myPhone: null,
      sendData: 0, // 短信发送数
      haveErify: false, //是否填写了验证码
      verifyHint: '', //显示验证码错误提示
      complainSMS: {
        text: '获取验证码',
        disabled: false,
        isCountDown: false
      },

      levelsEum: {
        1: '普通',
        2: '稀有',
        3: '史诗',
        4: '传说'
      },

      sendNum: 0,
      server_time: moment(),
      allCheckedList: [],
      pointTip: false,
      pointSum: 0
    });
    onMounted(async () => {
      await store.dispatch('refreshLoginInfo');
      state.myPhone = store.state.loginInfo.mobile;

      getList();
    });

    const sureStep = async () => {
      const { data } = await isonesendsms();
      state.sendData = data;
      if (data == 0) {
        // 当日第一次需要发送短信验证
        state.step = 3;
      } else {
        confirmSend();
      }
    };

    const getList = () => {
      getusercollectionsfold({ tab_type: state.tab_type, is_hidden: 1 }).then(res => {
        state.loading = false;
        state.collectionList = res.data.items.map(item => {
          // if (item.status !== 2 || item.regular_status !== 1) {}
          return {
            ...item,
            addnum: 1,
            int:
              item.items_num -
              item.trans_num -
              item.regular_num -
              item.wishing_num -
              item.rights_num,
            checked: false
          };
        });
        console.log('this.collectionList', state.collectionList, state.collectionList.length);

        const from = route.query.from;

        if ('sand' === from && localStorage.getItem('sandpay_mobile')) {
          // 如果来自衫德支付认证成功
          getLocalData();
        }
        console.log('state.collectionList', state.collectionList);
      });
    };

    const getLocalData = () => {
      state.sendPrice = localStorage.getItem('sandpay_price');
      state.mobile = localStorage.getItem('sandpay_mobile');
      state.items_id_list = JSON.parse(localStorage.getItem('sandpay_items_id_list'));
      state.sendUserInfo = JSON.parse(localStorage.getItem('sandpay_send_user_info'));
      state.checkedList = JSON.parse(localStorage.getItem('sandpay_check_list'));

      state.infoShow = true;
      state.sendTip = false;
      state.step = 2;
      localStorage.removeItem('sandpay_price');
      localStorage.removeItem('sandpay_mobile');
      localStorage.removeItem('sandpay_items_id_list');
      localStorage.removeItem('sandpay_send_user_info');
      localStorage.removeItem('sandpay_check_list');
    };

    // 判断是否有选中需要转赠的藏品
    const isShowInfo = (isfilter = true) => {
      state.pointTip = false;
      // alert(1);
      //
      if (isfilter) {
        filterLearnEnd();
      }
      // console.log(state.allCheckedList);
      // return;
      if (!state.pointTip) {
        state.items_id_list = getChooseList();
        console.log('state.items_id_list', state.items_id_list);
        if (state.items_id_list.length) {
          state.mobile = '';
          state.infoShow = true;
          state.verify_code = null;
        } else {
          toastFn({ message: '请选择转赠藏品' });
        }
      }
    };

    const filterLearnEnd = () => {
      state.pointTip = false;
      state.pointSum = 0;
      for (let i in state.collectionList) {
        if (state.collectionList[i].checked) {
          state.collectionList[i].items.slice(0, state.collectionList[i].addnum).forEach(row => {
            //
            if (row.regular_status == 1 && diffTime(row.end_time) < 0) {
              state.pointTip = true;
              state.pointSum += row.points;
            }
          });
        }
      }
    };

    // 遍历出选择的藏品
    const getChooseList = () => {
      console.log(1);
      let items_id_list = [];
      state.checkedList = [];
      let allCheckedList = [];
      for (let i in state.collectionList) {
        if (state.collectionList[i].checked) {
          // 过滤出items里面权益修炼完成积分未领取的数据
          // for (let j in state.collectionList[i].rights_items) {
          //   for (let k in state.collectionList[i].items) {
          //     console.log(
          //       state.collectionList[i].rights_items[j].id,
          //       state.collectionList[i].items[k].id
          //     );
          //     if (
          //       state.collectionList[i].rights_items[j].id == state.collectionList[i].items[k].id
          //     ) {
          //       allCheckedList.unshift(state.collectionList[i].items[k]);
          //     }
          //   }
          // }

          // console.log('allCheckedList', allCheckedList);

          let itemRow = state.collectionList[i].items
            .filter(row => {
              return row.status === 1 && row.regular_status !== 1;
            })
            .slice(0, state.collectionList[i].addnum);

          let itemIds = itemRow.map(item => {
            return item.id;
          });
          // console.log('itemIds', itemIds);

          allCheckedList = allCheckedList.concat(itemRow);
          // items_id_list = allCheckedList.map(item => {
          //   return item.id;
          // });
          items_id_list = items_id_list.concat(itemIds);
        }
      }
      state.allCheckedList = allCheckedList;
      if (allCheckedList.length) {
        console.log(2);
        for (let i in allCheckedList) {
          if (state.checkedList.length) {
            for (let j in state.checkedList) {
              if (
                allCheckedList[i].levels == state.checkedList[j].levels &&
                allCheckedList[i].items_name == state.checkedList[j].items_name &&
                allCheckedList[i].open_status == state.checkedList[j].open_status
              ) {
                state.checkedList[j].num++;
                break;
              } else {
                // alert(i + j);
                if (j >= state.checkedList.length - 1) {
                  state.checkedList.push({
                    levels: allCheckedList[i].levels,
                    open_status: allCheckedList[i].open_status,
                    items_name: allCheckedList[i].items_name,
                    display_name: allCheckedList[i].display_name,
                    num: 1
                  });
                  // break;
                }
              }
            }
          } else {
            state.checkedList.push({
              levels: allCheckedList[i].levels,
              items_name: allCheckedList[i].items_name,
              open_status: allCheckedList[i].open_status,
              display_name: allCheckedList[i].display_name,
              num: 1
            });
          }
        }
      }

      // console.log(allCheckedList, state.checkedList);

      // console.log('state.items_id_list', state.items_id_list);

      return items_id_list;
      // return state.items_id_list;
    };

    // 验证手机号码
    const submitPhone = () => {
      if (state.mobile) {
        nftUtils.setcnzzRecordByBtn('实名认证验证手机号');
        contributeCertification({
          receiver: state.mobile
        }).then(res => {
          console.log(res);
          if (res.status == 0) {
            if (
              state.sendPrice > 0 &&
              store.state.loginInfo != null &&
              !store.state.loginInfo.sandpay_cloud_account
            ) {
              // 走sandpay支付认证
              localStorage.setItem('sandpay_price', state.sendPrice);
              localStorage.setItem('sandpay_mobile', state.mobile);
              localStorage.setItem('sandpay_items_id_list', JSON.stringify(state.items_id_list));
              localStorage.setItem('sandpay_send_user_info', JSON.stringify(res.data));
              localStorage.setItem('sandpay_check_list', JSON.stringify(state.checkedList));
              console.log(JSON.stringify(state.checkedList));
              sandpayCloud({}).then(res => {
                console.log(res);
                if (res.status == 0) {
                  window.location.href = res.data.url;
                }
              });
            } else {
              state.sendTip = false;
              state.sendUserInfo = res.data;
              state.step = 2;
            }
          } else {
            // state.infoShow = false
            toastFn({ message: res.message });
            return false;
          }
        });
      } else {
        toastFn({ message: '请输入手机号或ID' });
      }
    };

    // 确定转赠
    const confirmSend = () => {
      nftUtils.setcnzzRecordByBtn('实名认证完确定转赠');
      if (!state.verify_code && state.sendData == 0) {
        toastFn({ message: '请填写验证码' });
        return;
      }
      makeCollection({
        id_list: state.items_id_list,
        to_user_id: state.sendUserInfo.user_id,
        verify_code: state.verify_code,
        price: state.sendPrice
      }).then(res => {
        console.log(res);

        if (res.status == 0) {
          state.infoShow = false;

          state.step = 1;
          state.collectionList = [];
          getList();
          toastFn({ message: '已提交转赠，待对方接收' });
        } else if (res.status == 421) {
          toastFn({ message: res.message, duration: 3000 });
          sandpayCloud({}).then(res => {
            console.log(res);
            if (res.status == 0) {
              window.location.href = res.data.url;
            }
          });
        } else {
          toastFn({ message: res.message });
          // return false;
        }
      });
    };

    //全选
    const chooseAllMeth = () => {
      state.chooseAll = !state.chooseAll;
      state.collectionList.map(item => {
        if (item.items_num !== item.trans_num) {
          item.checked = state.chooseAll ? true : false;
        }
      });
    };

    const sendNum = () => {
      state.sendNum = 0;
      for (let i in state.collectionList) {
        if (state.collectionList[i].checked) {
          state.sendNum = state.sendNum + state.collectionList[i].addnum;
        }
      }
    };

    //选择藏品
    const chooseColl = item => {
      if (item.items_num === item.trans_num) {
        toastFn({ message: '该藏品已无可转赠的啦~' });
        return;
      }
      item.checked = !item.checked;
      sendNum();
    };

    //取消
    const cancel = () => {
      router.go(-1);
    };

    const stepChange = (val, item) => {
      // console.log(val, item.int);
      if (val > item.int) {
        toastFn({ message: `该藏品只有${item.int}个可转赠` });
      }
      sendNum();
    };

    const verifyBlur = () => {
      state.haveErify = !!state.verify_code;
      state.verifyHint = !state.haveErify ? '验证码不能为空' : '';
    };

    const clickSendSms = () => {
      nftUtils.setcnzzRecordByBtn('点击发送验证码');
      const tel = state.myPhone;
      console.log(tel);
      if (!nftUtils.mobileTest(tel)) {
        toastFn('请输入正确的手机号码');
        return;
      }
      sendSms({ mobile: state.myPhone })
        .then(res => {
          if (res.status === 0) {
            toastFn({ message: '发送成功~' });
            return res.data;
          } else {
            toastFn({ message: res.message });
          }
        })
        .catch(err => {
          toastFn(err.message || '提交失败，稍后再试。');
        })
        .then(() => {
          nftUtils.countDownFn(state.complainSMS);
        });
    };

    const closeInfoShow = () => {
      state.infoShow = false;
    };

    const priceTip = () => {
      if (!state.sendPrice) {
        toastFn({ message: '请输入转赠价格' });
        return;
      }
      if (!state.mobile) {
        toastFn({ message: '请输入手机号或ID' });
        return;
      }
      if (state.mobile) {
        if (state.sendPrice == 0) {
          state.sendTip = true;
        } else {
          submitPhone();
        }
      } else {
        toastFn({ message: '请输入手机号或ID' });
      }
    };

    const closePop = () => {
      state.sendPrice = null;
      state.sendUserInfo = {};
      state.step = 1;
    };

    const inputPriceRule = (value, decimal) => {
      console.log(value, decimal);
      if (!value) {
        return value;
      }
      if (value == '-') {
        return null;
      }
      //限制小数位
      if (decimal > 0) {
        let regex = new RegExp(`^\\d+(?:\\.\\d{0,${decimal}})?`, 'g');
        value = (value && value.match(regex)[0]) || null;
        console.log(value);
      }
      return value;
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(state.server_time, 'millisecond');
    };

    const toLearning = () => {
      state.pointTip = false;
      router.push({ path: '/Learning/retreat' });
    };

    return {
      ...toRefs(state),
      chooseColl,
      chooseAllMeth,
      cancel,
      coll_no_choose: require('../imgs/icon/coll_no_choose.png'),
      coll_choose: require('../imgs/icon/coll_choose.png'),
      submitPhone,
      confirmSend,
      isShowInfo,
      stepChange,
      getChooseList,
      verifyBlur,
      clickSendSms,
      closeInfoShow,
      priceTip,
      closePop,
      inputPriceRule,
      diffTime,
      toLearning,
      sureStep
    };
  }
};
</script>
<style>
.addnum .van-stepper__input {
  margin: 0px;
  background: #1c172a;
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
.addnum {
  .intSend {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    // line-height: 26px;
  }

  .van-stepper__minus--disabled,
  .van-stepper__plus--disabled {
    opacity: 0.3;
  }
}

.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
.choose-title {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #494359;
  justify-content: space-between;
  font-size: 12px;
}
.appTop {
  padding-top: 70px !important;
}
.fixed-bootm {
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 11px 14px 11px;
  background-color: $bc;
  .bt-head {
    padding-bottom: 16px;

    .footerBtn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .btn-none {
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      width: 90px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 9px;
      font-size: 16px;
      font-weight: 500;
    }
    .btn {
      width: 90px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      color: #1c172a;
      font-weight: 500;
    }
  }
}
* {
  box-sizing: border-box;
}
.coll-box {
  padding-bottom: 110px;
}
.stepper {
  --van-stepper-background-color: #494359;
  --van-stepper-button-disabled-color: rgba(73, 67, 89, 0.5);
  --van-stepper-button-disabled-icon-color: rgba(255, 255, 255, 0.2);
  --van-stepper-button-icon-color: #fff;
  --van-stepper-input-text-color: #fff;
  --van-stepper-input-disabled-background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  // height: 26px;
  overflow: hidden;
  font-size: 0px;
}

.flex {
  display: flex;
}
.wrapper {
  padding: 14px;
}
.coll-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 27px 0 20px 0;
  border-bottom: 1px solid #494359;
  .item-left {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 28px;
    height: 28px;
    font-size: 22px;
  }
  .img-box {
    position: relative;
    font-size: 0px;
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 4px;
    }
    .tranNum {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: -7px;
      padding: 0 4px;
      line-height: 18px;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 4px 4px 4px 4px;
      font-size: 10px;
      font-weight: 500;
      color: #1c172a;
    }
  }
  .content {
    margin-top: 12px;
    margin-left: 12px;
  }
  .display_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }
  .items_name {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 26px;
  }
}

.infoPopup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 32px 0 24px 0;

  .sendTips {
    padding: 18px 4px 0 9px;
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    line-height: 16px;

    p {
      margin: 0;
      margin-top: 8px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .tips {
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    line-height: 20px;
    text-align: left;
    // margin-top: 30px;
  }

  .flexBox {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;

    .label {
      width: 100px;
      text-align: right;
      padding: 0 10px;
    }

    .labelVerify {
      width: 80px;
    }

    .van-cell {
      width: 147px;
      border-radius: 4px 4px 4px 4px;
      background: #1c172a;
    }

    .verify {
      width: 105px;
      height: 44px;
      background: #1c172a;
      --van-field-placeholder-text-color: rgba(255, 255, 255, 0.3);
    }

    .verifyBox {
      position: relative;
      display: flex;
    }

    .verify-warn {
      position: absolute;
      top: 100%;
      left: 0;
      color: $redc;
      font-size: 12px;
      margin-top: 10px;
      height: 12px;
    }
    .sendverify {
      margin-left: 10px;
      width: 91px;
      height: 44px;
      font-size: 14px;
      --van-button-default-background-color: $bc;
      --van-button-plain-background-color: $bc;
      --van-button-default-border-color: $fc;
      --van-button-border-width: 1px;
      --van-button-default-color: $fc;
      --van-button-border-radius: 4px;
    }

    .sendMsg {
      text-align: left;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);

      > div {
        margin-bottom: 12px;
      }

      .type {
        padding: 0 5px;
        color: white;
        border-radius: 2px;
        border: 1px solid #c1bfc5;
        margin-right: 3px;
      }
    }
  }

  .itemCenter {
    align-items: center;

    .priceRate {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 14px;
    }

    .mobileInput {
      width: 190px;
      --van-field-placeholder-text-color: rgba(255, 255, 255, 0.3);
    }
    .priceInput {
      --van-field-placeholder-text-color: rgba(255, 255, 255, 0.3);
    }
  }

  .priceItem {
    display: flex;
    align-items: center;
    .van-cell {
      width: 90px;
      margin-right: 8px;
    }
  }

  ::v-deep .van-field__control {
    color: #fff;
  }

  .input {
    background: #1c172a;
    border-radius: 4px 4px 4px 4px;
    color: white;
    margin: 20px 0;
  }

  .btns {
    display: flex;
    justify-content: space-around;
    padding-top: 14px;

    div {
      width: 116px;
      line-height: 36px;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 4px 4px 4px 4px;
      color: #1c172a;
      font-size: 16px;
      font-weight: 500;
    }

    .cancelBtn {
      border: 1px solid rgba(255, 255, 255, 0.5);
      color: #ffffff;
      background: #2f293f;
    }
  }
}
</style>

<style lang="scss">
#collectionSend {
  .van-popup {
    background: transparent !important;
  }
}
</style>
